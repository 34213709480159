import common from '@/factory/common'
import { throttle } from 'lodash'
import fn from '@/library/fn'
import store from '@/store'

export default {
  methods: {
    isLogged () {
      if (!fn.loggedIn()) return
      common.stillLogged()
        .catch(err => {
          if (err.status === 401 && err.code === 'tokenException')
            store.commit('SET_SESSION_EXP', true)
        })
    },
    appClick () {
      this.bounce(this)
    },
    bounce: throttle ((_this) => {
      _this.isLogged()
    }, 100000)
  }
}