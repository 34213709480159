<template>
  <div v-if="showError" class="mt-2 text-danger">
    Field <span class="text-bold">{{ label }}</span> {{ msg }}
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        default: 'Value'
      },
      field: {
        default: null
      }
    },
    computed: {
      showError () {
        if (this.field === null) return false
        return this.field.$error
      },
      msg () {
        if (!this.showError) return ''
        return this.field.$errors[0].$message.replace('{0}', '')
      }
    }
  }
</script>