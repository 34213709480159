import axios from 'axios'
import noty from '@/library/notification'
import fn from '@/library/fn'
import { each } from 'lodash'

const loggedIn = () => !!localStorage.token

const process = (result) => {
  return new Promise((resolve, reject) => {
    const status = result.status || 200
    if (status === 202) {
      return reject({
        lang: 'accepted'
      })
    }
    resolve(result.data)
  })
}

const trimNull = (obj) => {
  if (obj instanceof FormData) return obj
  const out = {}
  each(obj, (val, key) => {
    if (val !== null) Object.assign(out, { [key]: val })
  })
  return out
}

export const post = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'post',
      url,
      data: trimNull(data),
      headers
    })
      .then(result => {
        return process(result)
      })
      .then(result => { resolve(result) })
      .catch(err => {
        const check = noty(err)
        reject(check)
      })
  })
}

export const loggedPost = (url, data) => {
  if (!loggedIn()) return Promise.reject()
  return post(url, data, {
    Authorization: 'Bearer ' + fn.token()
  })
}

export const put = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'put',
      url,
      data: trimNull(data),
      headers
    })
      .then(result => {
        return process(result)
      })
      .then(result => { resolve(result) })
      .catch(err => {
        const check = noty(err)
        reject(check)
      })
  })
}

export const loggedPut = (url, data) => {
  if (!loggedIn()) return Promise.reject()
  return put(url, data, {
    Authorization: 'Bearer ' + fn.token()
  })
}

const del = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'delete',
      url,
      data: trimNull(data),
      headers
    })
      .then(result => {
        return process(result)
      })
      .then(result => { resolve(result) })
      .catch(err => {
        const check = noty(err)
        reject(check)
      })
  })
}

export const loggedDel = (url, data) => {
  if (!loggedIn()) return Promise.reject()
  return del(url, data, {
    Authorization: 'Bearer ' + fn.token()
  })
}

export const get = (url, params, headers) => {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'get',
      url,
      params,
      headers
    })
      .then(result => {
        return process(result)
      })
      .then(result => { resolve(result) })
      .catch(err => {
        const check = noty(err)
        reject(check)
      })
  })
}

export const loggedGet = (url, params) => {
  if (!loggedIn()) return Promise.reject()
  return get(url, params, {
    Authorization: 'Bearer ' + fn.token()
  })
}

const dl = (url, params, headers) => {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'get',
      responseType: 'blob',
      url,
      params,
      headers
    })
      .then(result => {
        return process(result)
      })
      .then(result => { resolve(result) })
      .catch(err => {
        const check = noty(err)
        reject(check)
      })
  })
}

export const loggedDl = (url, params) => {
  return dl(url, params, {
    Authorization: 'Bearer ' + fn.token()
  })
}

export const ajax = axios
