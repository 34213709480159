import Vue from 'vue'
import Router from 'vue-router'
import { requireAuth, requireUnAuth, logout } from '@/library/router-middleware'

const Login = () => import(/* webpackChunkName: "event" */ './views/Login.vue')
const PageNotFound = () => import(/* webpackChunkName: "event" */ './views/PageNotFound.vue')
const MainMenu = () => import(/* webpackChunkName: "event" */ './views/MainMenu.vue')
const HistoryQuota = () => import(/* webpackChunkName: "event" */ './views/HistoryQuota.vue')
const EmailAllocated = () => import(/* webpackChunkName: "event" */ "./views/EmailAllocated.vue");
const MtargetCompanyDetail = () => import(/* webpackChunkName: "event" */ './views/MtargetCompanyDetail.vue')
const MailtargetCompanyDetail = () => import(/* webpackChunkName: "event" */ './views/MailtargetCompanyDetail.vue')
const PartnerClientCreation = () => import(/* webpackChunkName: "event" */ './views/PartnerClientCreation.vue')

Vue.use(Router)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // WHITELABEL CMS
    {
      path: "/new-partner-client/:appName",
      name: "partner-client-creation",
      component: PartnerClientCreation,
      beforeEnter: requireAuth,
      meta: { reference: "Dashboard" },
    },
    {
      path: "/dashboard/:appName",
      name: "dashboard",
      component: MainMenu,
      beforeEnter: requireAuth,
      meta: { reference: "Dashboard", title: process.env.VUE_APP_COMPANY },
    },
    {
      path: "/dashboard",
      redirect: "/dashboard/mtarget",
    },
    {
      path: "/history-quota/:appName",
      name: "history-quota",
      component: HistoryQuota,
      beforeEnter: requireAuth,
      meta: { reference: "Dashboard", title: "History Quota" },
    },
    {
      path: "/email-allocated/:appName",
      name: "email-allocated",
      component: EmailAllocated,
      beforeEnter: requireAuth,
      meta: { reference: "Dashboard", title: "Detail Email Quota Allocated" },
    },
    {
      path: "/clients/:appName/:company/:companyId",
      name: "mtarget-company-detail",
      component: MtargetCompanyDetail,
      beforeEnter: requireAuth,
      meta: { reference: "Dashboard", title: "MTARGET Client Detail" },
    },
    {
      path: "/clients/:appName/:company/:companyId/:userId",
      name: "mailtarget-company-detail",
      component: MailtargetCompanyDetail,
      beforeEnter: requireAuth,
      meta: { reference: "Dashboard", title: "Mailtarget Client Detail" },
    },

    // MTARGET CMS LEGACY
    {
      path: "/",
      name: "home",
      beforeEnter(to, from, next) {
        next("/dashboard/mtarget");
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      beforeEnter: requireUnAuth,
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: logout,
    },
    {
      path: "*",
      name: "page-not-found",
      component: PageNotFound,
    },
  ],
});
