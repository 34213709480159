import Vue from 'vue'
import dayjs from 'dayjs'

Vue.filter('capitalize', (value) => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('dtm', (value) => {
  if (!value) return ''
  return dayjs(value).format('YYYY-MM-DD HH:mm')
})

Vue.filter('dtm2', (value) => {
  if (!value) return ''
  return dayjs(value).format('DD-MMM-YYYY')
})

Vue.filter('dtmType', (value, type) => {
  if (!value) return ''
  if (typeof type !== 'string') return ''
  return dayjs(value).format(type)
})

Vue.filter('curr', (value, toFixed = 0) => {
  if (!value) return '0'
  if (value === 'Unlimited') return value
  return parseFloat(value).toFixed(toFixed).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, `$1,`)
  // let s = Math.floor(value).toString()
  // let i = s.length % 3
  // let h = i > 0 ? (s.slice(0, i) + (s.length > 3 ? '.' : '')) : ''
  // return h + s.slice(i).replace(/(\d{3})(?=\d)/g, '$1.')
})

Vue.filter('replace', (value, reg = '_', to = ' ') => {
  if (!value) return ''
  let regex = new RegExp(reg, 'g')
  return value.replace(regex, to)
})

Vue.filter('lower', (value) => {
  if (!value) return value
  if (typeof value !== 'string') return value
  return value.toLowerCase()
})

Vue.filter('upper', (value) => {
  if (!value) return value
  if (typeof value !== 'string') return value
  return value.toUpperCase()
})

Vue.filter('round', (value = 0, decimals = 0) => {
  if (!value) return value
  return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
})

Vue.filter('formatNumber', (value) => {
  if (!value) return value
  if (!Number.isInteger(value)) return value
  return new Intl.NumberFormat('en-US').format(value);
})
