<template>
  <th @click="order">
    <span style="padding: 0 4px;">
      <i :class="iconClass"></i>
    </span>
    <slot></slot>
  </th>
</template>

<script>
  export default {
    props: {
      field: {
        default: null
      },
      filter: {
        default () {
          return {}
        }
      }
    },
    computed: {
      sort () { return this.filter.sort || '' },
      sortField () { return this.sort.replace('-', '') },
      isUp () { return this.sort.substring(0, 1) !== '-' && this.sortField === this.field },
      iconClass () {
        let x = ''
        x += this.sortField === this.field ? 'text-info ' : 'text-muted '
        x += this.isUp ? 'flaticon2-arrow-up ' : 'flaticon2-arrow-down '
        return x
      }
    },
    methods: {
      order () {
        this.$emit('sort', this.isUp ? '-' + this.field : '' + this.field)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/_config";
  th {
    cursor: pointer;
    &:hover {
      background-color: kt-get($kt-base-colors, grey, 1);
    }
  }
</style>