import Vue from 'vue'
import Vuex from 'vuex'
import cookie from 'js-cookie'
import { assign } from 'lodash'

Vue.use(Vuex)

const setAppKelas = (state) => {
  const defaultKelas = [
    'kt-quick-panel--right',
    'kt-demo-panel--right',
    'kt-offcanvas-panel--right',
    // 'kt-header--fixed',
    'kt-header-mobile--fixed',
    'kt-subheader--enabled',
    'kt-subheader--solid',
    // 'kt-subheader--fixed',
    'kt-aside--enabled',
    // 'kt-aside--fixed',
    'kt-page--loading'
  ]
  const kl = assign([], defaultKelas)
  if (state.asideEnable) kl.push('kt-aside--fixed')
  if (state.asideMinimize) kl.push('kt-aside--minimize')
  if (state.mobileAsideMaximize) kl.push('kt-aside--on')
  if (state.mobileHeader) kl.push('kt-header__topbar--mobile-on')
  state.appKelas = kl
}

export default new Vuex.Store({
  state: {
    notifications: [],
    userData: null,
    asideEnable: false,
    asideMinimize: false,
    mobileAsideMaximize: false,
    mobileHeader: false,
    sessionExpired: true,
    appKelas: [],
    draftPricing: null,
  },
  getters: {
    userRoles (state) {
      if (state.userData === null) return []
      return state.userData.roles
    }
  },
  mutations: {
    ADD_NOTIFICATION (state, val) {
      const id = Math.random()
      let noty = state.notifications
      val.id = id
      noty.push(val)
      setTimeout(() => {
        const idx = noty.findIndex(item => { return item.id === id })
        noty.splice(idx, 1)
      }, 5000)
    },
    DEL_NOTIFICATION (state, id) {
      let noty = state.notifications
      const idx = noty.findIndex(item => { return item.id === id })
      noty.splice(idx, 1)
    },
    SET_AUTH_DATA (state, val) {
      state.userData = val
    },
    SET_ASIDE_ENABLE (state, val) {
      state.asideEnable = val
      setAppKelas(state)
    },
    SET_ASIDE (state, val) {
      state.asideMinimize = val
      cookie.set('layout-aside', val)
      setAppKelas(state)
    },
    SET_MOB_MENU (state, val) {
      state.mobileAsideMaximize = val
      setAppKelas(state)
    },
    SET_MOB_HEADER (state, val) {
      state.mobileHeader = val
      setAppKelas(state)
    },
    SET_SESSION_EXP (state, val) {
      state.sessionExpired = val
    },
    SET_APP_KELAS (state, val) {
      state.appKelas = val
    },
    SET_DRAFT_PRICING (state, val) {
      state.draftPricing = val
    },
  },
  actions: {
    setDraftPricing ({ commit }, payload) { commit('SET_DRAFT_PRICING', payload) },
  }
})
