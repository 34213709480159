import fn from '@/library/fn'
import store from '@/store'

const checker = (res) => {
  let failRes = {
    code: 400,
    lang: 'nullResponse',
    asPopup: false,
    data: '',
    message: 'Failed to connect to server'
  }
  if (res === false) return failRes
  if (res === undefined) {
    failRes.lang = 'offline'
    return failRes
  }
  const status = res.status || 555

  if (res.data === undefined) return failRes
  if (res.data.code === undefined) return failRes

  const code = res.data.code
  const message = res.data.message
  const data = res.data.data

  let asPopup = true

  return {
    status,
    code,
    message,
    data,
    lang: code,
    asPopup: asPopup
  }
}

const notification = (err) => {
  let type = 'warn'
  let msg = 'Default error!'

  const check = err.lang === undefined ? checker(err.response) : err

  switch (check.status) {
  case 403:
    switch (check.code) {
    case 410:
      msg = check.data || check.message
      break
    case 413:
      switch (check.data) {
      case 'expired token':
        store.commit('SET_SESSION_EXP', true)
        msg = 'Token Expired!'
        break
      case 'wrong password':
        msg = 'Wrong Password!'
        break
      default:
        msg = check.data || check.message
        break
      }
      break
    default:
      msg = check.data || check.message
      break
    }
    break
  default:
    msg = check.data || check.message
    break
  }

  switch (type) {
    case 'warn':
      fn.notyWarn(msg)
      break
    case 'success':
      fn.notySuccess(msg)
      break
    case 'info':
      fn.notyInfo(msg)
      break
    case 'error':
      fn.notyError(msg)
      break
  }

  return check
}

export default notification