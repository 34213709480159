import fn from '@/library/fn'
import user from '@/factory/user'
import store from '@/store'
import common from '@/factory/common'

export const requireAuth = (to, from, next) => {
  if (!fn.loggedIn()) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }
  Promise.all([
    store.state.userData === null ? user.getUserData() : null,
    from.name !== null ? common.stillLogged() : null
  ])
    .then(res => {
      const [user] = res
      if (user !== null) {
        store.commit('SET_AUTH_DATA', user)
        store.commit('SET_MOB_MENU', false)
        store.commit('SET_SESSION_EXP', false)
      }

      // set draft pricing to null if to page subscription but from other than preview
      const toUpgrade = ['company-update-plan', 'company-edit-plan', 'company-edit-proforma'].includes(to.name)
      const fromPreview = ['company-update-plan-confirmation', 'company-edit-plan-confirmation', 'company-edit-proforma-confirmation'].includes(from.name)
      if (toUpgrade && !fromPreview) {
        store.dispatch('setDraftPricing', null)
      }

      next()
    })
    .catch(err => {
      if  (err.status === 401 && err.code === 'tokenException') {
        fn.logout()
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }
    })
}

export const requireUnAuth = (to, from, next) => {
  if (fn.loggedIn()) {
    return next('/dashboard')
  }
  next()
}

export const logout = (to, from, next) => {
  fn.logout()
  next('/login')
}
