import { loggedGet, get, loggedPost, loggedPut, loggedDel } from '@/library/ajax'
const apiUrl = process.env.VUE_APP_API_URL
const mtargetApiUrl = process.env.VUE_APP_MTARGET_API_URL

export default {
  stillLogged: () => loggedGet(apiUrl + '/ping', {}),
  getCountry: () => get(mtargetApiUrl + '/country', {}),
  getCity: (country) => get(mtargetApiUrl + '/city', { country }),
  getPacket: (filter = {}) => loggedGet(apiUrl + '/packet', filter),
  getDetailPacket: (packetId) => loggedGet(apiUrl + `/packet/${packetId}`, {}),
  getPacketExtraQuota: () => loggedGet(apiUrl + '/packet/extra', {}),
  getFeatures: () => loggedGet(apiUrl + '/packet/features', {}),
  createPacket: (data = {}) => loggedPost(apiUrl + '/packet', data),
  updatePacket: (packetId, data = {}) => loggedPut(apiUrl + `/packet/${packetId}`, data),
  deletePacket: (packetId) => loggedDel(apiUrl + `/packet/${packetId}`, {})
}