<template>
  <div class="alert-list">
    <div v-for="item in notifications" :key="item.id" :class="item.mode" class="alert fade show" role="alert">
      <div class="alert-icon"><i :class="item.icon"></i></div>
      <div class="alert-text">{{ item.message }}</div>
      <div class="alert-close">
        <button @click="close(item.id)" type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true"><i class="la la-close"></i></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'

  export default {
    computed: {
      notifications () { return store.state.notifications }
    },
    methods: {
      close (id) {
        store.commit('DEL_NOTIFICATION', id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .alert-list {
    position: fixed;
    bottom: 10px;
    right: 10px;
    max-width: 60%;
  }
</style>