import Vue from 'vue'

Vue.directive('numeric', {
  bind(el) {
    el.addEventListener('keyup', () => {
      let regex = /^\d*$/
      if (!regex.test(el.value)) {
        el.value = el.value.slice(0, -1)
      }
    })
    el.addEventListener('blur', () => {
      el.value = el.value.replace(/\D/g,'')
    })
  }
})