import store from '@/store'
import cookie from 'js-cookie'

export default {
  computed: {
    appKelas () { return store.state.appKelas }
  },
  created () {
    const kl = cookie.get('layout-aside')
    store.commit('SET_ASIDE', kl === 'true')
  }
}