import store from '@/store'

const fn = {
  loggedIn () {
    return !!localStorage.token
  },
  token () {
    return localStorage.token
  },
  logout () {
    delete localStorage.token
  },
  notyWarn (msg) {
    if (msg === 'validation') msg = 'form validation failed'
    store.commit('ADD_NOTIFICATION', {
      message: msg,
      icon: 'flaticon-warning',
      mode: 'alert-warning'
    })
  },
  setLs (key, val) {
    localStorage[key] = val
  },
  notySuccess (msg, data) {
    let m = msg
    if (data !== undefined) {
      data.forEach((element, key) => {
        m = m.replace(`{${key}}`, element)
      })
    }
    store.commit('ADD_NOTIFICATION', {
      message: msg,
      icon: 'flaticon-success',
      mode: 'alert-success'
    })
  },
  notyInfo (msg) {
    store.commit('ADD_NOTIFICATION', {
      message: msg,
      icon: 'flaticon-info',
      mode: 'alert-info'
    })
  },
  notyError (msg) {
    store.commit('ADD_NOTIFICATION', {
      message: msg,
      icon: 'flaticon-fail',
      mode: 'alert-danger'
    })
  },
  makeid(length) {
    if (length === undefined) length = 6
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  uriParams(data) {
    return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
  },
  userGrowthTelemarketerOptions () {
    return ['Yopie', 'AB', 'Fadlan', 'Negi', 'Ajie', 'Marvin', 'Radit', 'Ridwan', 'Patrick Jensen', 'Leona', 'Aldi', 'Naufal', 'Pram', 'Mario', 'Vina', 'Desy', 'Fauziah', 'Other']
  },
  userGrowthStatusOptions () {
    return ['Call Attempted', 'Callback', 'Priority', 'Disqualified', 'Demo', 'Meeting Scheduled', 'Payment', 'Emailed', 'Lost']
  },
}

export default fn