import { post, loggedGet, loggedPost, loggedDel } from '@/library/ajax'
import fn from '@/library/fn'
import store from '@/store.js'

const apiUrl = process.env.VUE_APP_API_URL

export default {
  login (data) {
    return new Promise((resolve, reject) => {
      post(apiUrl + '/reseller/signin', data)
        .then(res => {
          fn.setLs('token', res)
          return this.getUserData()
        })
        .then(res => {
          store.commit('SET_AUTH_DATA', res)
          store.commit('SET_SESSION_EXP', false)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  getUserData () {
    return loggedGet(apiUrl + '/me', {})
  },
  logout () {
    return loggedGet(apiUrl + '/logout', {})
  },
  getUsers (filter) {
    return loggedGet(apiUrl + '/users', filter)
  },
  saveUser (data) {
    return loggedPost(apiUrl + '/users/create', data)
  },
  updateUser (data) {
    return loggedPost(apiUrl + '/users/update', data)
  },
  deleteUser (id) {
    return loggedDel(apiUrl + '/users/delete', { id })
  },
  changePassword (data) {
    return loggedPost(apiUrl + '/users/change-password', data)
  },
  checkPassword (data) {
    return loggedPost(apiUrl + '/users/check-password', data)
  }
}
