<template>
  <div
    id="app"
    :class="appKelas"
    class=""
    @click="appClick"
  >
    <router-view/>
    <Alert/>
  </div>
</template>

<script>
  import Alert from '@/components/partial/Alert'
  import StillLoggedMixin from '@/components/login/StillLoggedMixin'
  import AsideMixin from '@/components/layout/AsideMixin'

  export default {
    mixins: [StillLoggedMixin, AsideMixin],
    components: {
      Alert
    }
  }
</script>
