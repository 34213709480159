import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { e } from '@/library/error-handler'

import PerfectScrollbar from 'vue2-perfect-scrollbar'

import '@/library/filter'
import '@/library/register-vee'
import '@/library/register-vuelidate'
import { initSentry } from '@/library/init-sentry'

import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import '@/library/components'
import '@/library/directive'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.min.css'

Vue.use(PerfectScrollbar)
Vue.config.productionTip = false

initSentry(Vue, router)

Vue.prototype.$e = e

new Vue({
  router,
  store,
  created () {
    setTimeout(() => {
    }, 1000)
  },
  render: h => h(App)
}).$mount('#app')
