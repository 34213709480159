import * as Sentry from '@sentry/vue'

const enableSentry = process.env.VUE_APP_SENTRY_ENABLE === 'true'
const sentryDsn = process.env.VUE_APP_SENTRY_DSN

export const initSentry = (Vue, router) => {
  if (!enableSentry) return
  // eslint-disable-next-line
  console.log('init sentry', sentryDsn)

  Sentry.init({
    Vue,
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", /^https:\/\/crm\.mtarget\.co/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend (ev) {
      return ev
    }
  });
}